// --------------------------------------------------------------------------
// ASPECT BASE
// --------------------------------------------------------------------------
//
// Aspect ratios for scaling media whilst keeping aspect ratios.
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.aspectVideo {
   position: relative;
   width: 100%;
   height: auto;

   background-color: rgba($Colour-black, .6);
}

.aspectVideo--iframe {
   position: relative;
   padding-top: percentage(9/16);

   iframe {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
   }
}

.aspectVideo-video {
   position: absolute;
   top: 0;
   left: 0;

   width: 100%;
   height: 100%;
}

.aspectVideo-message {
   @include font-size($FontSize-c3);
   position: absolute;
   top: 24px;
   left: 50%;
   transform:translateX(-50%);
   padding: halve($BSU) $BSU;
   z-index: 9;
   white-space: nowrap;
   text-align: center;

   background-color: $Colour-white;
   box-shadow: 0 0 8px rgba($Colour-black, .25);
   border-radius: $br;
}

.aspectVideo-messageType {
   @include font-size($FontSize-c4);
   position: absolute;
   top: 12px;
   right: 12px;
   padding: halve($BSU) $BSU;
   z-index: 9;
   text-align: center;

   background-color: $Colour-white;
   box-shadow: 0 0 8px rgba($Colour-black, .25);
   border-radius: $br;
}
